<template>
  <v-footer
    height="100"
    class="justify-start justify-md-center px-1 px-md-4"
  >
    <social-media :large="$vuetify.breakpoint.mdAndUp" />
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
    <v-btn
        :small="!$vuetify.breakpoint.mdAndUp"
        class="ma-1 ma-md-2"
        color="info"
        :to="$route.name === 'Imprint' ? '/' : '/imprint'"
    >
      {{ $t(`footer.${route}`) }}
    </v-btn>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',
    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },
    computed: {
      route() {
        return this.$route.name
      }
    }
  }
</script>
